<template>
  <!-- <v-app>
    <v-main>
      <contestant-list />
    </v-main>
  </v-app> -->

  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
// import ContestantList from '@/components/ContestantList.vue'; // Ensure correct path

export default {
  name: 'App',
  // components: {
  //   ContestantList
  // }
};
</script>

<style>
@import 'vuetify/styles'; /* Ensure Vuetify styles are imported */
</style>
