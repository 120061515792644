// src/router/index.js or wherever your router is set up
import { createRouter, createWebHistory } from 'vue-router';
import PollResults from '../../src/components/PollResults.vue';
import Home from '../../src/components/ContestantList.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
      },
  {
    path: '/poll-results',
    name: 'PollResults',
    component: PollResults,
  },
  // other routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
