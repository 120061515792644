<template>
    <v-container>
        
        <v-card
            class="elevation-12"
            style=" 
                height: 100px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff; /* Consider using an accent color here */
                border-radius: 30px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            "
            >
            <v-row style="width: 100%;">  
              <v-col cols="4" md="4" style="display: flex; justify-content: flex-start;">
                <v-img
                  :src="require('@/assets/channels4_profile.jpg')"
                  alt="Laza Idol"
                  aspect-ratio="1"
                  class="profile-image"
                  max-width="100px"
                  style="
                    border-radius: 15%;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                  "
                ></v-img>
              </v-col>
              <v-col
                cols="4" md="4"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  class="title"
                  style="
                    color: #1d4354;
                    /* font-size: 5vw; Responsive font size */
                    font-weight: bold;
                    margin-bottom: 0px;
                    text-align: center;
                  "
                >
                ላዛ ኣይዶል

            </div>
                <div
                  class="subtitle"
                  style="
                    color: #1d4354;
                    /* font-size: 15px; */
                    line-height: 1.5;
                    margin-top: 0;
                    text-align: center;
                  "
                >
                5ይ ዙር
          </div>
              </v-col>
              <v-col cols="4" md="4" style="display: flex; justify-content: flex-end;">
                <v-img
                  :src="require('@/assets/photo_2024-05-29_10-40-04.jpg')"
                  alt="Laza Idol"
                  aspect-ratio="1"
                  class="profile-image"
                  max-width="100px"
                  style="
                    border-radius: 15%;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                  "
                ></v-img>
              </v-col>
              </v-row>
            </v-card>
  <!-- <nav>
    <v-app-bar app dark style="background-color:lightslategray;">
      <v-btn icon to="/">
        <v-avatar size="60">
            <v-icon class="mr-2">fas fa-video</v-icon>
                </v-avatar>
      </v-btn>
      <div class="d-none d-md-flex">
        <v-btn text class="ml-4"  to="/">Home</v-btn>
        <v-btn text class="ml-4"  to="/feedbacks">Live Result</v-btn>
        <v-btn text class="ml-2"  to="/questions">Poll</v-btn>
      </div>
      <v-spacer></v-spacer>
            <v-divider></v-divider>
    </v-app-bar>
  </nav> -->
<!-- New Router Link -->
<!-- Loading Bar -->
<v-progress-linear
      v-if="loading"
      indeterminate
      color="rgb(0, 96, 128)"
      class="mb-4"
    ></v-progress-linear>
<div style="text-align: center; margin-top: 20px;margin-bottom: 20px;">
      <!-- <router-link to="/poll-results"> -->
        <router-link :to="{ name: 'PollResults' }">

        <v-btn color="rgb(0, 96, 128)">ውፅኢት መረፃ ርኣዩ</v-btn>
      </router-link>
    </div>

      <div class ="contestants">
        <div v-for="contestant in contestants" :key="contestant.id" class="contestant card">
          <div class="contestant-photo-wrapper">
            <img :src="contestant.photo" :alt="contestant.name" class="contestant-photo">
          </div>
          <div  style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                border-radius: 5px;
                background-color: #f5f5f5;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                ">
                <h2  style="
                    font-size: 20px;
                    color: #333;
                    margin-bottom: 10px;
                ">{{ contestant.name }}</h2>
                <div  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 10px;
                ">
                    <p  style="
                    font-size: 16px;
                    color: #777;
                    margin: 0;
                    ">ድምፂ: {{ contestant.count }}</p>
                    <p  style="
                    font-size: 16px;
                    color: #777;
                    margin: 0;
                    ">ደረጃ: {{ getRanking(contestant) }}</p>
                </div>
                <div  style="
                    display: flex;
                    justify-content: center;
                    width: 100%;
                ">
                    <button @click="vote(contestant.id)"  style="
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 14px;
                    border-radius: 5px;
                    transition: background-color 0.3s;
                    ">ድምፂ ሃቡ</button>
                    <button @click="viewDetails(contestant)"  style="
                    background-color: #ddd;
                    color: #333;
                    border: 1px solid #ccc;
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 14px;
                    border-radius: 5px;
                    transition: background-color 0.3s;
                    margin-left: 10px;
                    ">ዝርዝር ርኣዩ</button>
                </div>
                </div>
        </div>
      </div>
     
  
      <!-- Snackbar for success and error messages -->
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" top style=" justify-items: center; ">
        {{ snackbar.message }}
        <v-btn color="white" text @click="snackbar.show = false">ይዕፀዉዎ</v-btn>
      </v-snackbar>
  
      <!-- Modal -->
      <div class="modal" :class="{ 'is-active': isModalActive }">
        <div class="modal-background" @click="closeModal"></div>
        <div v-if="selectedContestant" class="modal-content">
          <div class="box">
            <h2>{{ selectedContestant.name }}</h2>
            <div class="modal-photo-wrapper">
              <img :src="selectedContestant.photo" :alt="selectedContestant.name" class="modal-photo">
            </div>
            <p>ድምፂ: {{ selectedContestant.count }}</p>
            <!-- Add other details here -->
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="closeModal" style="color:red">ዕፀዎ</button>
      </div>





      <!-- <v-card
  class="elevation-12"
  style="
    /* margin-bottom: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; /* Consider using an accent color here */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    width: 100%;
  "
>
  <v-row style="width: 80%;">
    
    <v-col cols="8" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">

        <h3  style="text-align: center; color: teal; font-size: 18px;">
  <a href="https://iq-businesses.com/" style="color: #333; text-decoration: none;">
    Developed by: <span style="color: teal;">IQ Business Group</span>
  </a>
</h3>



      </v-col>
  </v-row>
</v-card> -->




<!-- <v-footer
   dark
   padless
 > -->
   <v-card
     class="teal white--text text-center"
     style="background-color:#fff; border-radius: 20px;"
   >
    
     <v-card-text class="white--text pt-0">
      
      </v-card-text>
 


     <v-divider></v-divider>

     <v-card-text class="white--text">
        <copyright>©</copyright> {{ new Date().getFullYear() }} - <strong> Developed By: <a class="white--text" href="https://iq-businesses.com/">IQ Business Group</a> </strong>
     </v-card-text>
   </v-card>
 <!-- </v-footer> -->
    </v-container>


    
  </template>
  
  <script>
  import axios from 'axios';
  import { VSnackbar, VBtn, VCard, VRow, VCol, VContainer } from 'vuetify/components';
  
  export default {
    components: {
      VSnackbar,
      VBtn,
      VCard,
      VRow,
      VCol,
      VContainer
    },
    data() {
      return {
        contestants: [],
        snackbar: {
          show: false,
          message: '',
          color: '',
        },
        isModalActive: false,
        selectedContestant: null,
        loading: true, // Add loading state

      };
    },
    methods: {
      fetchContestants() {
        axios.get('https://idolapi.iq-businesses.com/contestants')
          .then(response => {
          this.contestants = this.shuffle(response.data);
          this.loading = false; // Stop loading once data is fetched

            // console.log(this.contestants)
          })
          .catch({
            // this.showSnackbar('ናይ ምትእስሳር ጸገም','Network Error', 'error');
            // console.log('');
          });
      },

      shuffle(array) {
      let currentIndex = array.length, temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
      vote(id) {
        axios.post('https://idolapi.iq-businesses.com/vote', { id }, { withCredentials: true })
          .then(response => {
            this.showSnackbar("ንተወዳዳሪ ድምጺ ሂብኹም ኣለኹም። ስለዝመረፁ ነመስግን!","success");
            console.log(!response.data);
            // Optionally, update the contestant's vote count or re-fetch the list
            this.fetchContestants();
          })
          .catch(error => {
            // console.log(error)
            this.showSnackbar("ንኻልኣይ ግዜ ድምጺ ክህቡ ኣይፍቀድን። ስለዝመረፁ ነመስግን!"  || error.status);
          });
      },
      getRanking(contestant) {
            const sortedContestants = this.contestants.slice().sort((a, b) => b.count - a.count);
  let rank = 1;
  let previousCount = null;

  // Loop through sorted contestants to assign ranks
  for (let i = 0; i < sortedContestants.length; i++) {
    const currentContestant = sortedContestants[i];

    // If the count is different from the previous count, update the rank
    if (currentContestant.count !== previousCount) {
      rank = i + 1;
    }

    // If the current contestant is the one we're looking for, return its rank
    if (currentContestant.id === contestant.id) {
      return rank;
    }

    // Update previous count for the next iteration
    previousCount = currentContestant.count;
  }

  // If all contestants have zero votes, they all receive rank 1
  if (previousCount === 0) {
    return 1;
  }
      },
      viewDetails(contestant) {
        this.selectedContestant = contestant;
        this.isModalActive = true;
      },
      closeModal() {
        this.isModalActive = false;
        this.selectedContestant = null;
      },
      showSnackbar(message, type) {
        this.snackbar.message = message;
        this.snackbar.color = type === 'success' ? 'green' : 'red';
        this.snackbar.show = true;
      }
    },
    created() {
      this.fetchContestants();
  
      this.ws = new WebSocket('wss://idolapi.iq-businesses.com');
      this.ws.onmessage = (event) => {
        this.contestants = JSON.parse(event.data);
      };
    },
    beforeUnmount() {
      this.ws.close();
    },
  };
  </script>
  
  <style>
  @import 'vuetify/styles'; /* Import Vuetify styles */

 body {
  font-family: 'Arial', sans-serif;
  background-color: #93b4d4;
  color: hsl(0, 0%, 20%);
  margin: 0;
  padding: 0;
}

.container {
  padding: 20px;
  text-align: center;
}
@keyframes title-animation {
  0% {
    opacity: 0;
    transform: scale(0.9);
    text-shadow: 0 0 0 rgb(68, 0, 128), 2px 2px 0 #1d4354, 4px 4px 0 #ff00bf;
  }
  25% { /* Adjust this percentage to control pop-in duration */
    opacity: 1;
    transform: scale(1);
    text-shadow: 0 0 0 rgb(0, 34, 128), 2px 2px 0 #0f0202, 4px 4px 0 #ff00bf;
  }
  50% {
    text-shadow: 0 0 2px #ff0000, 2px 2px 0 #ff8000, 4px 4px 0 #ffff00;
  }
  75% {
    text-shadow: 0 0 4px #37a000, 2px 2px 0 #0080ff, 4px 4px 0 #0000ff;
  }
  100% {
    text-shadow: 0 0 6px #1100ff, 2px 2px 0 #ff8000, 4px 4px 0 #00ffff;
  }
}
@keyframes subtitle-animation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.title {
  color: transparent; /* Hide the base text color */
  animation: title-animation 3.5s ease-in-out forwards alternate infinite;
}


.subtitle {
    animation: subtitle-animation 3.5s ease-in-out forwards alternate infinite;

  /* font-size: 2vw; */
  color: #1d4354;
  margin-bottom: 20px;
}

.contestant-name {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.vote-count,
.ranking,
.modal-vote-count {
  font-size: 18px;
  color: #555;
  margin-bottom: 5px;
}
h1 {
  margin-bottom: 20px;
  color: #007bff;
}

.alert {
  width:fit-content;
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.contestants {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.contestant {
  background-color: #37a000;
  border-radius: 30px;
  border-color: #007bff;
  border: 1px solid #007bff; /* Adjust border width and color as needed */
  /* padding: 20px; */
  box-shadow: 0 12px 15px rgba(238, 2, 2, 0.1);
  overflow: hidden;
  width: calc(100% - 20px); /* Full width minus margins */
  margin-bottom: 20px;
  text-align: center;
  transition: transform 0.3s;
}

@media (min-width: 768px) {
  .contestant {
    width: calc(33.33% - 20px); /* Adjust width for larger screens */
  }
}

.contestant:hover {
  transform: translateY(-10px);
  box-shadow: 0 19px 10px rgba(249, 7, 7, 0.2);
}

/* New wrapper for the image */
.contestant-photo-wrapper {
  width: 100%;
  height: 280px; /* Fixed height for the circular avatar */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align the image to the bottom */
  overflow: hidden; /* Hide any overflow content */
  background-color: #e6e8ea; /* Background color to fill the space */
  margin-bottom: 15px;
}

.contestant-photo {
  width: auto; /* Auto width to maintain aspect ratio */
  height: 100%; /* Take up the full height of the wrapper */
  border-radius: 50%; /* Makes the image circular */
}

.contestant-details {
  padding: 15px;
}
/* Flexbox container for votes and ranking */
.contestant-stats {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin: 10px 0;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.contestant-stats p {
  margin: 0;
  padding: 0 10px;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.vote-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.vote-button:hover {
  background-color: #0056b3;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  overflow: auto;
}
  
  .modal.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  /* Wrapper for the image in the modal */
.modal-photo-wrapper {
  width: 100%;
  justify-items: center;
  text-align: center;
  margin-bottom: 15px;
}
.modal-photo {
  width: 100%;
  height: auto;
  border-radius: 0; /* Ensures the image is not circular */
  object-fit: contain; /* Ensures the entire image fits within the container */
}
  .modal-content {
  position: relative;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  width: 70%;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }

  .modal-photo-wrapper {
    height: auto;
  }

  .modal-photo {
    width: 100%;
    height: auto;
  }
}
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

v-card {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
    .name {
      font-size: 1em; /* Font size for small screens */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .name {
      font-size: 1em; /* Font size for medium screens */
    }
  }

  @media (min-width: 1025px) {
    .name {
      font-size: 1.5em; /* Font size for large screens */
    }
  }

  @media (max-width: 600px) {
    .title {
      font-size: 1.5em; /* Font size for small screens */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .title {
      font-size: 2em; /* Font size for medium screens */
    }
  }

  @media (min-width: 1025px) {
    .title {
      font-size: 3.5em; /* Font size for large screens */
    }
  }
  @media (max-width: 600px) {
    .subtitle {
      font-size: 1em; /* Font size for small screens */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .subtitle {
      font-size: 1em; /* Font size for medium screens */
    }
  }

  @media (min-width: 1025px) {
    .subtitle {
      font-size: 1.5em; /* Font size for large screens */
    }
  }


/* img {
  max-width: 90%;
  height: auto;
} */
  </style>
  