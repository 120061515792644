    <template>
        <v-container>

            <v-card
                class="elevation-12"
                style="
                    height: 100px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff; /* Consider using an accent color here */
                border-radius: 30px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                "
                >
                <v-row style="width: 100%;">  

                    <v-col cols="4" md="4" style="display: flex; justify-content: flex-start;">
                    <v-img
                    :src="require('@/assets/channels4_profile.jpg')"
                    alt="Laza Idol"
                    aspect-ratio="1"
                    class="profile-image"
                    max-width="100px"
                    style="
                        border-radius: 15%;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    "
                    ></v-img>
                </v-col>
                <v-col
                cols="4" md="4"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  class="title"
                  style="
                    color: #1d4354;
                    /* font-size: 5vw; Responsive font size */
                    font-weight: bold;
                    margin-bottom: 0px;
                    text-align: center;
                  "
                >
                ላዛ ኣይዶል

            </div>
                <div
                  class="subtitle"
                  style="
                    color: #1d4354;
                    /* font-size: 15px; */
                    line-height: 1.5;
                    margin-top: 0;
                    text-align: center;
                  "
                >
                5ይ ዙር

        </div>
                </v-col>
                <v-col cols="4" md="4" style="display: flex; justify-content: flex-end;">
                    <v-img
                    :src="require('@/assets/photo_2024-05-29_10-40-04.jpg')"
                    alt="Laza Idol"
                    aspect-ratio="1"
                    class="profile-image"
                    max-width="70px"
                    style="
                        border-radius: 15%;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    "
                    ></v-img>
                </v-col>
                    <!-- <v-col cols="4" style="display: flex; justify-content: center;">Vote for Your Favorite Contestant!  </v-col> -->
                </v-row>
                </v-card>


        <v-card class="elevation-12" style="padding: 20px; background-color: #f7f7f7; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
            <v-row style="width: 100%;">
                <div style="text-align: center; margin-top: 20px;margin-bottom: 20px;">
        <!-- <router-link to="/poll-results"> -->
            <router-link :to="{ name: 'Home' }">

            <v-btn color="rgb(0, 96, 128)">ናብ መረፃ ይመለሱ</v-btn>
        </router-link>
        </div>
            <v-col cols="12" style="text-align: center;">
                <h2 style="color: rgb(0, 96, 128); font-size: 32px; font-weight: bold; margin-bottom: 20px;"><u> ውፅኢት መረፃ </u></h2>
                <h3 style="color: rgb(0, 96, 128); font-size: 22px; font-weight: bold; margin-bottom: 20px;">ጠቕላላ ድምፂ : {{ totalVotes }}</h3>

                <v-progress-circular v-if="loading" :size="70" :width="7" indeterminate color="rgb(0, 96, 128)"></v-progress-circular>
                <v-row v-if="!loading">
                <v-col v-for="(contestant, index) in sortedContestants" :key="contestant.id" cols="12" md="12" style="margin-bottom: 20px;">
                    <div class="contestant-info" style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
                        <span class = "name" >{{ index + 1 }}. 
                            <a href="#" @click.prevent="openModal(contestant)">
                                {{ contestant.name.split(/\s+ኮድ\s+\d+/)[0].trim() }}   

                    </a>
                    </span>
                    <div class="progress-container" style="flex: 1; margin-left: 20px;">
                        <v-progress-linear
                            v-model="contestant.count"
                            :max="totalVotes"
                            color="rgb(0, 96, 128)"
                            height="14"
                            >
                            <template v-slot:default="">
                                <strong style="font-size: 13px;">{{ getPercentage(contestant.count) }}%</strong>
                            </template>
                        </v-progress-linear>
                        <!-- <v-progress-linear
                            :value="getPercentage(contestant.count)"
                            color="blue"
                            background-color="lightgray"
                            height="10"
                        ></v-progress-linear>         -->
                    </div>
                    <!-- <span style="font-size: 13px; color: teal; font-weight: bold; margin-left: 20px;">{{ getPercentage(contestant.count) }}%</span> -->
                    </div>
                </v-col>
                </v-row>
            </v-col>
            </v-row>
        </v-card>


        <v-card
        class="teal white--text text-center"
        style="background-color:#fff; border-radius: 20px;"
    >
        
        <v-card-text class="white--text pt-0">
        
        </v-card-text>
    
        <v-divider></v-divider>

    <v-card-text class="white--text">
    <copyright>©</copyright> {{ new Date().getFullYear() }} - <strong> Developed By: <a class="white--text" href="https://iq-businesses.com/">IQ Business Group</a></strong>
    </v-card-text>
    </v-card>
    <!-- Modal for Contestant Details
    <v-dialog v-model="isModalActive" max-width="600px">
        <v-card>
            <v-card-title class="headline">{{ selectedContestant?.name }}</v-card-title>
            <v-card-text>
            <v-img :src="selectedContestant?.photo" :alt="selectedContestant?.name" aspect-ratio="1" max-width="300px"></v-img>
            <p>Votes: {{ selectedContestant?.count }}</p>
            Add other contestant details here -->
            <!-- </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="isModalActive = false">Close</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog> --> 



    <!-- Dialog for contestant details -->
<v-dialog v-model="isModalActive" max-width="700">
  <v-card class="contestant-dialog">
    <v-card-title class="dialog-title">ናይ ተወዳደርቲ ሓበሬታ:</v-card-title>
    <v-carousel v-model="selectedContestantIndex" cycle hide-delimiter-background >
      <v-carousel-item v-for="(contestant) in sortedContestants" :key="contestant.id" >
        <v-card-text>
          <!-- Display contestant details here -->
          <div v-if="selectedContestant" class="contestant-details">
            <div class="contestant-photo-wrapper">
              <img :src="contestant.photo" alt="Contestant Photo" class="contestant-photo">
            </div>
            <div class="detail-container">
              <div class="detail-item"><strong>ሽም:</strong> {{ contestant.name.split(/\s+ኮድ\s+\d+/)[0].trim() }}</div>
              <div class="detail-item"><strong>ኮድ:</strong> {{ contestant.code }}</div>
              <div class="detail-item"><strong>ነጥቢ:</strong> {{ contestant.count }}</div>
              <!-- Add other contestant details as needed -->
            </div>
          </div>
        </v-card-text>
      </v-carousel-item>
    </v-carousel>
    <v-card-actions>
      <v-btn color="teal" @click="isModalActive = false">ይዕፀዉዎ</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


    <!-- </v-footer> -->
    </v-container> 
    </template>
    
    
    
    
    
    
    
    
    
    <script>
    import axios from 'axios';
    
    export default {
        data() {
        return {
            contestants: [],
            loading: true,
            isModalActive: false,
            selectedContestant: null,
            selectedContestantIndex: 0 // Add this line to define selectedContestantIndex



        };
        },
        computed: {

        totalVotes(){
          return this.contestants.reduce((sum, contestant) => sum + contestant.count, 0);
        },
        sortedContestants() {
            return [...this.contestants].sort((a, b) => b.count - a.count);
            // return [...this.contestants];

        }
        },
        methods: {

        openModal(contestant) {
        this.selectedContestant = contestant;
        this.isModalActive = true;
        },
        fetchContestants() {
            axios
            .get('https://idolapi.iq-businesses.com/contestants', { withCredentials: true })
            .then(response => {
                this.contestants = response.data;
                this.loading = false;
            })
            .catch(error => {
              const er = '' && error
                 console.error(er);
              
                this.loading = true;
            });
        },
        getPercentage(count) {
            const totalVotes = this.contestants.reduce((sum, contestant) => sum + contestant.count, 0);
            return totalVotes ? ((count / totalVotes) * 100).toFixed(2) : 0;
        }
        },
        created() {
        this.fetchContestants();
        this.intervalId = setInterval(this.fetchContestants, 1000); // Fetch every 1 second

        },
        beforeUnmount() {
        if (this.intervalId) {
        clearInterval(this.intervalId); // Clear the interval when component is destroyed
        }
    }

    };
    </script>
    
    <style>
    /* Styles for the new component */
    .contestant-card {
        transition: transform 0.3s, box-shadow 0.3s;
    }
    .contestant-card:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
    .contestant-dialog {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }

    .dialog-title {
    background-color: rgb(0, 96, 128);
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 20px;
    }

    .contestant-details {
    padding: 20px;
    display: flex;
    justify-content: center;
    }

    .avatar-container {
    margin-right: 20px;
    }

    .detail-container {
    border-left: 5px solid rgb(0, 96, 128);
    padding-left: 20px;
    }

    .detail-item {
    margin-top: 15px;
    }

    /* New wrapper for the image */
    .contestant-photo-wrapper {
    width: 90%;
    height: 280px; /* Fixed height for the circular avatar */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align the image to the bottom */
    overflow: hidden; /* Hide any overflow content */
    background-color: #e6e8ea; /* Background color to fill the space */
    margin-bottom: 15px;
    }

    .contestant-photo {
    width: auto; /* Auto width to maintain aspect ratio */
    height: 100%; /* Take up the full height of the wrapper */
    border-radius: 50%; /* Makes the image circular */
    }

    .title {
    color: #1d4354;
    /* font-size: 2em; Default font size */
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
  }

  @media (max-width: 600px) {
    .name {
      font-size: 1em; /* Font size for small screens */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .name {
      font-size: 1em; /* Font size for medium screens */
    }
  }

  @media (min-width: 1025px) {
    .name {
      font-size: 1.5em; /* Font size for large screens */
    }
  }

  @media (max-width: 600px) {
    .title {
      font-size: 1.5em; /* Font size for small screens */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .title {
      font-size: 2em; /* Font size for medium screens */
    }
  }

  @media (min-width: 1025px) {
    .title {
      font-size: 3.5em; /* Font size for large screens */
    }
  }
  @media (max-width: 600px) {
    .subtitle {
      font-size: 1em; /* Font size for small screens */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .subtitle {
      font-size: 1em; /* Font size for medium screens */
    }
  }

  @media (min-width: 1025px) {
    .subtitle {
      font-size: 1.5em; /* Font size for large screens */
    }
  }

  
    </style>